import { useState, useEffect, useRef, SetStateAction } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDropdown, SCFieldset } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { CheckboxChangeParams } from 'primereact/checkbox';


const EntradaCartao = () => {
    const toast = useToast();
    const { periodoSelecionado, usuario } = useAuth();
    const tarefa = 'LiquidaFaturaCartao';

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [aPagar, setAPagar] = useState<any>([]);
    const [cartao, setCartao] = useState<any>([]);

    const methods = useForm();
    const { control, watch, getValues, setValue } = methods;

    const watchCartaoInstitucional = watch('CartaoInstitucionalId')
    const watchDataI = watch('DataInicial')
    const watchDataF = watch('DataFinal')

    const load = async () => {
        const result = await api.get('/cartoes/listCartaoInstitucional', {
            params:
            {
                Institucional: 1,
                Tipo: 'D'
            }
        });
        if (result.data) {
            setCartao(result.data);
        } else {
            setCartao([])
        }

        const formattedDataI = watchDataI ? new Date(watchDataI).toISOString().split('T')[0] : null;
        const formattedDataF = watchDataF ? new Date(watchDataF).toISOString().split('T')[0] : null;

        const result2 = await api.get('/apagar/listLiquidaFatura', {
            params: {
                DataI: formattedDataI,
                DataF: formattedDataF,
                CartaoInstitucionalId: watchCartaoInstitucional,
                FaturasQuitadas: false
            }
        })
        if (result2.data) {
            setAPagar(result2.data)
        } else {
            setAPagar([])
        }
    };

    useEffect(() => {
        load();
    }, []);

    const handleCheckboxChange = (rowData: any, checked: boolean) => {
        const updatedAPagar = aPagar.map((item: any) => {
            if (item.ApagarId === rowData.ApagarId) {
                return { ...item, Selecionado: checked ? 1 : 0 };
            }
            return item;
        });

        setAPagar(updatedAPagar);
    };

    const onSubmit = async () => {
        try {
            let valorTotal = 0;
            const hours = String(new Date().getHours()).padStart(2, '0');
            const minutes = String(new Date().getMinutes()).padStart(2, '0');
            const formattedTime = `${hours}:${minutes}`;

            aPagar.forEach((item: any) => {
                if (item.Selecionado === 1) {
                    valorTotal += item.Valor;
                }
            });

            const dados = {
                Valor: valorTotal,
                LancamentoData: new Date(),
                LancamentoHora: formattedTime,
                LancamentoOperador: usuario?.Apelido,
            }

            const novaFatura = await api.post('/liquidaFatura', dados);
            const idFatura = novaFatura.data[0];

            await Promise.all(
                aPagar.map(async (item: any) => {
                    if (item.Selecionado === 1) {
                        await api.put('/apagar/liquidaFatura', {
                            ApagarId: item.ApagarId,
                            LiquidaFaturaId: idFatura,
                        });
                    }
                })
            );

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            load()

        } catch (e: any) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    };

    const clickHandler = async () => {
        load()
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Entrada De Cartao</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>
                    <SCFieldset legend='Filtros' className='col-12 mt-2 pt-0'>
                        <div className='grid'>
                            <div className="col-12 md:col-2">
                                <SCCalendar
                                    control={control}
                                    label='Data Crédito Inicial'
                                    name='DataInicial'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    placeholder='Data Inicial'
                                />
                            </div>

                            <div className="col-12 md:col-2">
                                <SCCalendar
                                    control={control}
                                    label='Data Crédito Final'
                                    name='DataFinal'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    placeholder='Data Final'
                                />
                            </div>
                            <div className="col-12 md:col-2">
                                <SCCalendar
                                    control={control}
                                    label='Data Lançamento Inicial'
                                    name='DataInicial'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    placeholder='Data Inicial'
                                />
                            </div>

                            <div className="col-12 md:col-2">
                                <SCCalendar
                                    control={control}
                                    label='Data Lançamento Final'
                                    name='DataFinal'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    placeholder='Data Final'
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <SCDropdown
                                    name="CartaoInstitucionalId"
                                    label='Cartão Institucional'
                                    options={cartao}
                                    optionLabel='NomeCartao'
                                    optionValue='CartoesId'
                                    value={watchCartaoInstitucional}
                                    onChange={(e) => setValue('CartaoInstitucionalId', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className='col-12 md:col-2 flex align-items-end'>
                                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined" type='button' onClick={clickHandler} />
                            </div>
                        </div>
                    </SCFieldset>
                    <DataTable
                        size='small'
                        stripedRows
                        value={aPagar}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        header={null}
                        responsiveLayout="scroll">
                        <Column field="NumeroDocumento" header="Nº Documento" sortable headerStyle={{ width: '10%' }} ></Column>
                        <Column field="DataPagamento" header="Data Pagamento" sortable body={(rowData: any) => {
                            if (rowData.DataPagamento) {
                                return (
                                    <span>{rowData.DataPagamentoString}</span>
                                );
                            }
                            return null;
                        }} style={{ width: '16%' }} />
                        <Column field="NomeCartao" header="Cartão Institucional" sortable headerStyle={{ width: '20%' }} ></Column>
                        <Column field="Nome" header="Credor" sortable headerStyle={{ width: '20%' }} ></Column>
                        <Column field="Referente" header="Referente" sortable headerStyle={{ width: '20%' }} ></Column>

                        <Column field="Valor" header="Valor" align='center' sortable body={(rowData: any) => {
                            return (
                                <span>R$ {rowData.Valor?.toFixed(2).replace('.', ',')}</span>
                            );
                        }} style={{ width: '15%' }}
                        />

                        <Column
                            body={(rowData: any) => (
                                <div className="col-12 flex justify-content-center">
                                    <SCCheckbox
                                        checked={rowData.Selecionado === 1}
                                        onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                                        id={rowData.ApagarId}
                                        name="Selecionado"
                                    />
                                </div>
                            )}
                            alignHeader='center'
                            align='center'
                        />
                    </DataTable>
                    <div className='grid flex justify-content-center'>
                        <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
                        <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
                            <Button label='Quitar Fatura' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EntradaCartao;