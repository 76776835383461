import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { TURNOS } from '../utilities/constantes';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCDialog, SCDropdown, SCInputSwitch, SCInputText, SCRadioButton } from '../components';
import Loading from '../components/Loading';
import apiRelatorio from '../services/apiRelatorios';

import LoadingReport from '../components/LoadingReport';
import { SlPrinter } from 'react-icons/sl';
import { SiMicrosoftexcel } from 'react-icons/si';
import TooltipOptions from 'primereact/tooltip/tooltipoptions';
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
import { Fieldset } from 'primereact/fieldset';
import SelectTurmaResumido from '../components/SelectTurmaResumido';
import SelectNivelSequencia from '../components/SelectNivelSequencia';
import SelectConvenio from '../components/SelectConvenio';
import { utils, writeFile } from 'xlsx';

interface IRelatorio_AlunosAssinatura {
  SomenteTurmasRegulares: boolean;
  Situacao: {
    Matriculado: boolean;
    Cancelado: boolean;
    Abandono: boolean;
    Transferido: boolean;
    Trancado: boolean;
    Registrado: boolean;
  };
  NivelSequenciaId?: number;
  ConvenioId?: number;
  TurmaCodigo?: string;
  Turno: string;
  ImprimirNumeroOrdem: boolean;
  ImprimirTotalAlunos : boolean;
  ImprimirSituacao: boolean;
  ImprimirNome: number;
  Colunas: string;
  Divisoes: string;
  ClassificadoPor: string;
}

const Relatorio_AlunosAssinatura = () => {
  const toast = useToast();
  const { usuario, empresaSelecionada, periodoSelecionado } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [verRelatorio, setVerRelatorio] = useState<any>(null);
  const [loadReport, setLoadReport] = useState<boolean>(false);
  const [dataExcel, setDataExcel] = useState<any[]>([]);
  const dt = useRef<DataTable>(null);
  const menu = useRef<any>(null);

  const tooltipOptions: TooltipOptions = {
    position: 'top',        
  }
      
  const defaultValues: IRelatorio_AlunosAssinatura = {
    SomenteTurmasRegulares: true,
    Situacao: {
      Matriculado: true,
      Cancelado: false,
      Abandono: false,
      Transferido: false,
      Trancado: false,
      Registrado: false,      
    },
    NivelSequenciaId: undefined,
    ConvenioId: undefined,
    TurmaCodigo: '',
    Turno: '',
    ImprimirNumeroOrdem: false,
    ImprimirTotalAlunos: false,
    ImprimirSituacao: false,
    ImprimirNome: 1,
    Colunas: '',
    Divisoes: '',
    ClassificadoPor: 'T+O'
  }

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;
   
  const getDados = async (filtros: any) => {
    const {data} = await api.get('/report/alunos/assinatura', { params: filtros });    
    return data;
  }

  const onSubmit = async (data: IRelatorio_AlunosAssinatura) => {

    const filtros = {
      EmpresaId: empresaSelecionada?.EmpresaId,
      Sequencial: periodoSelecionado?.Sequencial,
      Ano: periodoSelecionado?.Ano,
      SomenteTurmasRegulares: data.SomenteTurmasRegulares,
      Situacao: '9'+(data.Situacao.Abandono ? ',A' : '') + (data.Situacao.Cancelado ? ',C' : '') + (data.Situacao.Registrado ? ',R' : '') + (data.Situacao.Matriculado ? ',M' : '') + (data.Situacao.Trancado ? ',S' : '') + (data.Situacao.Transferido ? 'T,N,X' : ''),
      Turno: data.Turno,
      NivelSequenciaId: data.NivelSequenciaId,
      ConvenioId: data.ConvenioId,
      TurmaCodigo: data.TurmaCodigo,
      ClassificadoPor: data.ClassificadoPor
    }
    
    setLoadReport(true);

    try {

      const data = await getDados(filtros);
      
      if (data.length == 0) {
        toast.showToast({ severity: 'info', summary: 'Informação', detail: 'Nenhum registro encontrado' });      
        setLoadReport(false)
        return
      }

      const restReport = await apiRelatorio.post('alunos/assinatura', {
        header: {
          idRelatorio: empresaSelecionada?.IdRelatorio,
          titulo: 'Relatação de Alunos para Assinaturas',
          subtitulo: data.ClassificadoPor == 'T+O' ? 'Organizado por Nº Ordem' : 'Organizado por Nome',
          Logo: empresaSelecionada?.Logo?.replace('data:image/png;base64,', '')
        },
        lista: data?.map((item: any) =>
        ({
          Nome: item.Nome,
          Matricula: item.Matricula,
          TurmaCodigo: item.TurmaCodigo,
          NumeroOrdem: item.NumeroOrdem,
          TurmaDescricao: item.TurmaDescricao,
        })
        )
      });
      setVerRelatorio(restReport.data);
    } catch (e) {
      console.log(e);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Erro ao gerar relatório' });
    }

    setLoadReport(false);
  }

  const onExcel = async () => {
    setLoadReport(true)
    const data = getValues();
    const filtros = {
      EmpresaId: empresaSelecionada?.EmpresaId,
      Sequencial: periodoSelecionado?.Sequencial,
      Ano: periodoSelecionado?.Ano,
      SomenteTurmasRegulares: data.SomenteTurmasRegulares,
      Situacao: '9'+(data.Situacao.Abandono ? ',A' : '') + (data.Situacao.Cancelado ? ',C' : '') + (data.Situacao.Registrado ? ',R' : '') + (data.Situacao.Matriculado ? ',M' : '') + (data.Situacao.Trancado ? ',S' : '') + (data.Situacao.Transferido ? 'T,N,X' : ''),
      Turno: data.Turno,
      NivelSequenciaId: data.NivelSequenciaId,
      ConvenioId: data.ConvenioId,
      TurmaCodigo: data.TurmaCodigo,
      ClassificadoPor: data.ClassificadoPor
    }

    const dados = await getDados(filtros);

    if (dados.length == 0) {
      toast.showToast({ severity: 'warn', summary: 'Informação', detail: 'Nenhum registro encontrado' });      
      setLoadReport(false)
      return
    }else{
       
      /* formatando os dados agrupando por turma*/      
      const turmas = dados.reduce((acc: any, item: any) => {
        if (!acc[item.TurmaCodigo]) {
          acc[item.TurmaCodigo] = [];
        }
        acc[item.TurmaCodigo].push({
          NumeroOrdem: String(item.NumeroOrdem),
          Matricula: String(item.Matricula),
          Nome: item.Nome,
          Assinatura: ''
        });
        return acc;
      }, {});

      const workbook = utils.book_new();

      Object.keys(turmas).forEach(turmaCodigo => {
        const worksheet = utils.json_to_sheet(turmas[turmaCodigo]);
        utils.sheet_add_aoa(worksheet, [["Nº Ordem", "Matrícula", "Nome", "Assinatura"]], { origin: "A1" });
        utils.book_append_sheet(workbook, worksheet, turmaCodigo);
      });

      writeFile(workbook, "AlunosAssinatura.xlsx", { compression: true });     
    }
   
    setLoadReport(false)
  }

  useEffect(() => {
    if (verRelatorio) {
      const iframe = document.querySelector("iframe");
      if (iframe)
        iframe.src = verRelatorio;
    }
  }, [verRelatorio])


  if (loading)
    return <Loading />

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className='pt-2' style={{paddingBottom: 60}}>

          {loadReport && (
            <LoadingReport full={true} />
          )}

          <div className="card">
            <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
              <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Relação de Alunos para Assinatura</h5>
            </div>
            
            <div className='grid mt-2'>
              <div className="col-12 md:col-6">
                <div className="flex align-items-center justify-content-center just p-2" style={{border: '1px solid #ccc', borderRadius: '5px'}}>
                  <SCRadioButton                           
                    control={control}
                    name="SomenteTurmasRegulares"
                    label='Somente Turmas Regulares'                            
                    value={true}
                  />
                  <SCRadioButton
                    control={control}
                    name="SomenteTurmasRegulares"
                    label='Somente Turams de Ed. Física'
                    value={false}
                    className='ml-3'
                  />
                </div>
              </div> 
            </div> 
            <Fieldset legend="Filtros" className="mt-2" style={{border: '1px solid #ccc', borderRadius: '5px'}}>
              <div className='grid'>              
                <div className="col-12">
                  <div className="flex align-items-center justify-content-center gap-2 p-2" style={{border: '1px solid #ccc', borderRadius: '5px'}}>
                    <SCInputSwitch 
                      label='Matriculado'
                      control={control}
                      name="Situacao.Matriculado" 
                      className="p-button-rounded p-button-info" 
                    />
                    <SCInputSwitch 
                      label='Cancelado'
                      control={control}
                      name="Situacao.Cancelado"
                      className="p-button-rounded p-button-info" 
                    />
                    <SCInputSwitch 
                      label='Abandono'
                      control={control}
                      name="Situacao.Abandono"
                      className="p-button-rounded p-button-info" 
                    />
                    <SCInputSwitch 
                      label='Transferido'
                      control={control}
                      name="Situacao.Transferido"
                      className="p-button-rounded p-button-info" 
                    />
                    <SCInputSwitch 
                      label='Trancado'
                      control={control}
                      name="Situacao.Transcado"
                      className="p-button-rounded p-button-info" 
                    />
                    <SCInputSwitch 
                      label='Fichado'
                      control={control}
                      name="Situacao.Registrado"
                      className="p-button-rounded p-button-info" 
                    />
                  </div>            
                </div> 
              </div>    

              <div className='grid'>
                <div className="col-6">
                  <SelectNivelSequencia
                    control={control}      
                    errors={errors}             
                    name='NivelSequenciaId'
                    label='Nível/Sequencia' 
                  />              
                </div>    
                <div className="col-6">
                  <SelectTurmaResumido
                    control={control}      
                    errors={errors}             
                    name='TurmaCodigo'
                    label='Turma' 
                    carregaDetalhe={false}                 
                  />              
                </div>  
              </div>         

              <div className='grid'>
                <div className="col-3">
                  <SCDropdown
                    errors={errors}
                    control={control}
                    name="Turno"
                    label='Turno'
                    options={TURNOS}
                    optionLabel='descricao'
                    optionValue='value'
                    filter={false}
                  />
                </div>   
                <div className="col-6">
                <SelectConvenio
                    control={control}      
                    errors={errors}             
                    name='ConvenioId'
                    label='Convênio' 
                  />              
                </div>  
              </div>       
            </Fieldset>
            
            <Fieldset legend="Configurações" className="mt-2" style={{border: '1px solid #ccc', borderRadius: '5px'}}>
              <div className='grid'>
                <div className="col-3">
                  <label>Imprimir o Nº de Ordem</label>
                  <div className="flex align-items-center justify-content-around p-2 " style={{border: '1px solid #ccc', borderRadius: '5px'}}>
                    <SCRadioButton                           
                        control={control}
                        name="ImprimirNumeroOrdem"
                        label='Sim'
                        value={true}
                      />
                    <SCRadioButton                           
                      control={control}
                      name="ImprimirNumeroOrdem"
                      label='Não'
                      value={false}
                      className='ml-2'
                    />
                  </div>
                </div>

                <div className="col-3">
                  <label>Imprimir o Total de Alunos</label>
                  <div className="flex align-items-center justify-content-around p-2 " style={{border: '1px solid #ccc', borderRadius: '5px'}}>
                    <SCRadioButton                           
                        control={control}
                        name="ImprimirTotalAlunos"
                        label='Sim'
                        value={true}                          
                      />
                    <SCRadioButton                           
                      control={control}
                      name="ImprimirTotalAlunos"
                      label='Não'
                      value={false}
                      className='ml-2'
                    />
                  </div>
                </div>
                
                <div className="col-3">
                  <label>Imprimir a Situação</label>
                  <div className="flex align-items-center justify-content-around p-2 " style={{border: '1px solid #ccc', borderRadius: '5px'}}>
                    <SCRadioButton                           
                        control={control}
                        name="ImprimirSituacao"
                        label='Sim'
                        value={true}
                      />
                    <SCRadioButton                           
                      control={control}
                      name="ImprimirSituacao"
                      label='Não'
                      value={false}
                      className='ml-2'
                    />
                  </div>
                </div>
              </div>
              <div className='grid'>
                <div className="col-7">                    
                    <label className='pr-3'>Imprimir o Nome: </label>
                    <div className="flex align-items-center justify-content-around p-2" style={{border: '1px solid #ccc', borderRadius: '5px'}}>
                      <SCRadioButton                           
                        control={control}
                        name="ImprimirNome"
                        label='Nome do Aluno'
                        value={1}                          
                      />
                      <SCRadioButton                           
                        control={control}
                        name="ImprimirNome"
                        label='Nome do Responsável'
                        value={2}
                        className='ml-3'
                      />
                      <SCRadioButton                           
                        control={control}
                        name="ImprimirNome"
                        label='Pai'
                        value={3}
                        className='ml-3'
                      />
                      <SCRadioButton                           
                        control={control}
                        name="ImprimirNome"
                        label='Mãe'
                        value={4}
                        className='ml-3'
                      />
                    </div>
                </div>
              </div>

              {/* <div className='grid'>
                <div className="col-10">   
                  <SCInputText 
                    control={control}
                    errors={errors}
                    name="Colunas"
                    label="Colunas"
                  />
                </div>
              </div>

              <div className='grid'>
                <div className="col-10">   
                  <SCInputText 
                    control={control}
                    errors={errors}
                    name="Divisoes"
                    label="Divisões"
                  />
                </div>
              </div> */}

            </Fieldset>
          
            <Fieldset legend="Organização" className="mt-2" style={{border: '1px solid #ccc', borderRadius: '5px'}}>
              <div className='grid'>
                <div className="col-7">                    
                  <label className='pr-3'>Classificado por </label>
                  <div className="flex align-items-center justify-content-around p-2" style={{border: '1px solid #ccc', borderRadius: '5px'}}>
                    <SCRadioButton                           
                      control={control}
                      name="ClassificadoPor"
                      label='Turma + Nº de Ordem'
                      value='T+O'                          
                    />
                    <SCRadioButton                           
                      control={control}
                      name="ClassificadoPor"
                      label='Turma + Nome'
                      value='T+N'
                      className='ml-3'
                    />
                   
                  </div>
                </div>
              </div>
              <div className='grid'>
                <div className="col-10">   
                  <SCInputText 
                    control={control}
                    errors={errors}
                    name="Titulo"
                    label="Título"
                  />
                </div>
              </div>

            </Fieldset>

            <Divider align="center" />                 

            <div id="actions-print" className="flex flex-1 justify-content-end pt-1" style={{ position: 'fixed', bottom: '20px', right: '30px', zIndex: 1000 }}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <SCButton tooltip="Exportar para Excel" tooltipOptions={tooltipOptions} 
                    className="p-button w-auto bg-green-400 border-green-400" style={{ width: '50' }} type='button' 
                    onClick={() => onExcel()}>
                    <SiMicrosoftexcel size={20} color="white"/>
                </SCButton>
                <SCButton type='submit' tooltip="Imprimir" tooltipOptions={tooltipOptions} 
                    className="p-button w-auto ml-3" style={{ width: '50', backgroundColor: '#0772B3', borderColor: '#0772B3' }}>
                    <SlPrinter size={20} color="white"/>
                </SCButton>
              </div>
            </div> 
            
            <SCDialog visible={verRelatorio != null} style={{ width: '80vw', height: '90vh' }} onHide={() => setVerRelatorio(null)}>                            
              <iframe src={verRelatorio} width="100%" height="100%"></iframe>              
            </SCDialog>             
          </div>
      </form>
      
    </FormProvider>
  );
};

export default Relatorio_AlunosAssinatura;
