import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IPlanoConta from '../interfaces/IPlanoConta';
import PlanoContaForm from './PlanoContaForm';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import { classNames } from 'primereact/utils';
import PlanoContaAlterarForm from './PlanoContaAlterarForm';

const PlanoConta = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado } = useAuth();
  const tarefa = 'PlanoContaForm';

  let emptyPlanoConta: IPlanoConta = {
    PlanoContaId: undefined,
    Classificacao: '',
    Conta: '',
    Descricao: '',
    Desativado: false,
    Tipo: '',
    DescricaoSimplificada: '',
    ContabilizaIR: false,
    ContabilizaDQ: false,
  };

  const [planosConta, setPlanosConta] = useState<any>(null);
  const [planoContaDialog, setPlanoContaDialog] = useState<boolean>(false);
  const [deletePlanoConta, setDeletePlanoConta] = useState<boolean>(false);
  const [planoConta, setPlanoConta] = useState<IPlanoConta>(emptyPlanoConta);
  const [selectedPlanoConta, setSelectedPlanoConta] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [novoComBase, setNovoComBase] = useState<any>()
  const [altClassiConta, setAltClassiConta] = useState<any>()
  const [altPlanoContaDialog, setAltPlanoContaDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyPlanoConta });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;
  let result: any;

  const formatClassificacao = (classificacao: string) => {
    // Verifica se a classificação já está formatada
    if (classificacao.includes('.')) return classificacao;

    // Insere pontos no padrão 1.2.13.02.0002
    let formatted = '';
    for (let i = 0; i < classificacao.length; i++) {
      formatted += classificacao.charAt(i);
      if (i === 0 || i === 1 || i === 3 || i === 5) {
        formatted += '.';
      }
    }
    // Remove ponto final, se houver
    return formatted.endsWith('.') ? formatted.slice(0, -1) : formatted;
  };

  const verificaFilhos = (classificacao: string, index: number, todasClassificacoes: any[]) => {
    // Verifica se existe próximo item
    if (index >= todasClassificacoes.length - 1) {
      return false; // É o último item, então não tem filhos
    }

    // Pega o próximo item
    const proximoItem = todasClassificacoes[index + 1];

    // Verifica se o próximo começa com a classificação atual + ponto
    const ehFilho = proximoItem.Classificacao.startsWith(classificacao + '.');

    // console.log({
    //   itemAtual: classificacao,
    //   proximoItem: proximoItem.Classificacao,
    //   ehFilho
    // });

    return ehFilho;
  };

  const load = async () => {
    setLoading(true);
    if (getValues()?.Descricao === '') {
      result = await api.get('/planoContas/list', { params: { Descricao: getValues()?.Descricao } });
    } else {
      result = await api.get('/planoContas', { params: { Descricao: getValues()?.Descricao } });
    }

    // Formata as classificações
    const classificacoesFormatadas = result.data.map((item: any) => ({
      ...item,
      Classificacao: formatClassificacao(item.Classificacao)
    }));

    // Verifica filhos comparando cada item com o próximo
    const planosContaFormatados = classificacoesFormatadas.map((planoConta: any, index: number) => ({
      ...planoConta,
      temFilhos: verificaFilhos(planoConta.Classificacao, index, classificacoesFormatadas)
    }));

    if (result.data)
      setPlanosConta(planosContaFormatados);
    else
      setPlanosConta([]);
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setNovoComBase(null)
    setPlanoConta(emptyPlanoConta);
    setSubmitted(false);
    setPlanoContaDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setPlanoContaDialog(false);
  };
  const editar = (planoConta: any) => {
    setNovoComBase(null)
    setPlanoConta(planoConta);
    setPlanoContaDialog(true);
  };

  const confirmDelete = (planoConta: any) => {
    setNovoComBase(null)
    setPlanoConta(planoConta);
    setDeletePlanoConta(true);
    setPlanoContaDialog(true);
  };

  const deletar = async () => {
    try {
      await api.delete('/planoContas', {
        params: { PlanoContaId: planoConta.PlanoContaId }
      });

      toast.showToast({
        severity: 'success',
        summary: 'Atenção',
        detail: ALERTAS.registroExcluido
      });

      load();
      setDeletePlanoConta(false);
      setPlanoContaDialog(false);
      setPlanoConta(emptyPlanoConta);

    } catch (erro: any) {
      toast.showToast({
        severity: 'error',
        summary: 'Erro',
        detail: erro?.response?.data?.error
      });
    }
  };

  const onSubmit = async (data: any) => {
    await load();
    dt.current.reset()
    console.log(dt.current);
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    // {
    //   label: 'Imp. de Excel',
    //   icon: 'pi pi-file-excel',
    //   template: () => (
    //     <FileUpload
    //       name="demo[]"
    //       customUpload
    //       uploadHandler={handleFileUpload}
    //       accept=".xlsx"
    //       //accept='.csv'
    //       auto
    //     />
    //   )
    // },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const stockBodyTemplate = (rowData: IPlanoConta) => {
    const stockClassName = classNames('pl-2 pr-2 h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-pink-700 text-pink-50': rowData.Tipo === 'SAÍDA',
      'bg-green-400 text-green-50': rowData.Tipo === 'ENTRADA',
    });

    return <div className={stockClassName}>{rowData.Tipo}</div>;
  };

  const novoComBaseNesse = (planoConta: any) => {
    setNovoComBase(planoConta.PlanoContaId)
    planoConta.PlanoContaId = undefined;
    setPlanoConta(planoConta);
    setPlanoContaDialog(true);
  };

  const alteraClassiConta = (planoConta: any) => {
    setNovoComBase(null)
    setPlanoConta(planoConta);
    setAltPlanoContaDialog(true);
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Plano de Contas</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />

          <DataTable
            loading={loading}
            size='small'
            stripedRows
            ref={dt}
            value={planosConta}
            onSelectionChange={(e) => setSelectedPlanoConta(e.value)}
            dataKey="id"
            paginator
            rows={10}
            stateKey='planoContaTable'
            stateStorage='local'
            pageLinkSize={10}
            rowsPerPageOptions={[10, 15, 20, 30, 50]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            header={null}
            responsiveLayout="scroll">
            <Column field="Classificacao" header="Classificação" sortable headerStyle={{ width: 92 }}></Column>
            <Column field="Conta" header="Conta" sortable ></Column>
            <Column field="Descricao" header="Descrição" sortable ></Column>
            <Column field="Tipo" header="Tipo" body={stockBodyTemplate}></Column>
            <Column field="DescricaoSimplificada" header="Descrição Simplificada" ></Column>
            <Column field="Valor" header="Valor" body={(rowData: any) => {
              return (
                <span>{rowData?.Valor?.toFixed(2)}</span>
              )
            }} ></Column>

            <Column body={(rowData: any) => {
              return (
                <div className="actions flex align-items-center justify-content-between">
                  <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                  <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                  <Button icon="pi pi-book" className="p-button-rounded  p-button-warning" tooltip='Altera Classificação/Conta' tooltipOptions={{ position: 'top' }} onClick={() => alteraClassiConta(rowData)} type='button' />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => confirmDelete(rowData)}
                    disabled={rowData.temFilhos}
                    tooltip={rowData.temFilhos ? 'Não é possível excluir pois possui itens vinculados' : 'Excluir'}
                    tooltipOptions={{ position: 'top' }}
                  />
                </div>
              );
            }} style={{ width: 300 }}
            />
          </DataTable>

          <SCDialog maximized={!util.isDesktop()} visible={planoContaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${planoConta.PlanoContaId === undefined ? 'Cadastrando ' : (deletePlanoConta ? 'Excluíndo' : 'Alterando')} Plano de Contas`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <PlanoContaForm
              setPlanoContaDialog={setPlanoContaDialog}
              planoConta={planoConta}
              NovoComBase={novoComBase}
              reload={load}
              deletePlanoConta={deletePlanoConta}
              setDeletePlanoConta={setDeletePlanoConta}
              deletar={deletar}
            />
          </SCDialog>

          <SCDialog maximized={!util.isDesktop()} visible={altPlanoContaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`Alterando Classificação/Conta`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <PlanoContaAlterarForm
              setAltPlanoContaDialog={setAltPlanoContaDialog}
              planoConta={planoConta}
              reload={load}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default PlanoConta;
