import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCDropdown, SCFieldset, SCInputText, SCRadioButton } from '../components';
import { Panel } from 'primereact/panel';
import { format } from 'date-fns';
import { ACAO_CAIXA } from '../utilities/constantes';
import Loading from '../components/Loading';


const EncerramentoCaixa = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const [usuarios, setUsuarios] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [habilitaExecutar, setHabilitaExecutar] = useState(false)

  // ENTRADAS
  const [entAbasteceMeuCaixa, setEntAbasteceMeuCaixa] = useState<number>(0);
  const [entDinheiroMeuCaixa, setEntDinheiroMeuCaixa] = useState<number>(0);
  const [entDinheiroOutrasFontes, setEntDinheiroOutrasFontes] = useState<number>(0);
  const [entCartaoMeuCaixa, setEntCartaoMeuCaixa] = useState<number>(0);
  const [entCartaoOutrasFontes, setEntCartaoOutrasFontes] = useState<number>(0);
  const [entOutrosMeuCaixa, setEntOutrosMeuCaixa] = useState<number>(0);
  const [entOutrosOutrasFontes, setEntOutrosOutrasFontes] = useState<number>(0);
  const [totalEntradaMeuCaixa, setTotalEntradaMeuCaixa] = useState<number>(0);
  const [totalEntradaOutrasFontes, setTotalEntradaOutrasFontes] = useState<number>(0);

  // SAIDAS
  const [saiSangriaMeuCaixa, setSaiSangriaMeuCaixa] = useState<number>(0);
  const [saiDinheiroMeuCaixa, setSaiDinheiroMeuCaixa] = useState<number>(0);
  const [saiCartaoMeuCaixa, setSaiCartaoMeuCaixa] = useState<number>(0);
  const [saiOutrosMeuCaixa, setSaiOutrosMeuCaixa] = useState<number>(0);
  const [saiDinheiroOutrasFontes, setSaiDinheiroOutrasFontes] = useState<number>(0);
  const [saiCartaoOutrasFontes, setSaiCartaoOutrasFontes] = useState<number>(0);
  const [saiOutrosOutrasFontes, setSaiOutrosOutrasFontes] = useState<number>(0);
  const [totalSaidaMeuCaixa, setTotalSaidaMeuCaixa] = useState<number>(0);
  const [totalSaidaOutrasFontes, setTotalSaidaOutrasFontes] = useState<number>(0);

  // RESUMO

  const [resumoPgMensalidade, setResumoPgMensalidade] = useState<number>(0);
  const [resumoPgBaixa, setResumoPgBaixa] = useState<number>(0);
  const [resumoOutrosRecebimentos, setResumoOutrosRecebimentos] = useState<number>(0);
  const [resumoEstornos, setResumoEstornos] = useState<number>(0);
  const [resumoAPagar, setResumoAPagar] = useState<number>(0);

  // SALDO TOTAIS
  const [saldoMeuCaixa, setSaldoMeuCaixa] = useState<number>(0);
  const [saldoOutrasFontes, setSaldoOutrasFontes] = useState<number>(0);
  const [saldoTotal, setSaldoTotal] = useState<number>(0);

  const [desabilita, setDesabilita] = useState(false)

  const methods = useForm();
  const { handleSubmit, control, formState: { errors }, watch, clearErrors, setError, setValue, getValues } = methods;

  const isMaster = usuario?.Master;
  const usuarioIdLogado = usuario?.UsuarioId;

  // Watchs
  const watchUsuario = watch('UsuarioId')
  const watchDataInicial = watch('DataInicial')
  const watchDataFinal = watch('DataFinal')
  const watchAcao = watch('Acao')

  const load = async () => {
    try {
      const { data } = await api.get('/usuario/listaUsuarioEmpresa', {
        params: { EmpresaId: periodoSelecionado?.EmpresaId },
      });

      const usuarios = isMaster ? data : data?.filter((user: any) => user.UsuarioId === usuarioIdLogado) || [];
      setUsuarios(usuarios);

    } catch (error) {
      console.error('Erro ao carregar usuários:', error);
      setUsuarios([]);
    }
  };

  // Função de pesquisa
  const loadPesquisa = async () => {
    // Validações iniciais
    if (!watchAcao) {
      return showToastError('Selecione a Ação');
    }

    if (!watchDataInicial) {
      return showToastError('Selecione a Data Inicial!');
    }

    if (!watchDataFinal) {
      return showToastError('Selecione a Data Final!');
    }

    if (watchAcao !== 'C') {
      if (!watchUsuario) {
        return showToastError('Selecione um Usuário!');
      }
    }

    setLoading(true)

    // Selecionando apenas a data no formato yyyy-MM-dd
    const dataIni = format(new Date(watchDataInicial), 'yyyy-MM-dd');
    const dataFin = format(new Date(watchDataFinal), 'yyyy-MM-dd');

    // Variáveis para cálculos
    let saidaDinheiroMeuCaixa = 0;
    let saidaDinheiroOutras = 0;
    let saidaCartaoMeuCaixa = 0;
    let saidaCartaoOutras = 0;
    let saidaOutrosMeuCaixa = 0;
    let saidaOutrosOutras = 0;

    // ENTRADAS
    let entradaDinheiroMeuCaixa = 0;
    let entradaDinheiroOutras = 0;
    let entradaCartaoMeuCaixa = 0;
    let entradaCartaoOutras = 0;
    let entradaOutrosMeuCaixa = 0;
    let entradaOutrosOutras = 0;

    // RESUMO
    let resumoPgtoMensalidade = 0;
    let resumoPgtoBaixa = 0;
    let resumoOutrosRec = 0;
    let resumoEstorno = 0;
    let resumoContasAPagar = 0;

    // SALDO
    let saldoMeuCaixa1 = 0;
    let saldoOutrasFontes1 = 0;

    // Função auxiliar para somar valores de um endpoint específico
    const fetchDataAndSum = async (endpoint: string, params: object, sumFields: string[], setState: Function) => {
      try {
        const { data } = await api.get(endpoint, { params });

        if (data) {
          const total = data.reduce((acc: number, item: any) => {
            return acc + sumFields.reduce((sum: number, field: string) => sum + (item[field] || 0), 0);
          }, 0);

          // Atualiza o estado com o valor formatado
          setState(total.toFixed(2));
          return total;
        }
      } catch (error) {
        console.error(`Erro ao obter os dados de ${endpoint}:`, error);
        return 0;
      }
    };

    // ENTRADA - ABASTECE MEU CAIXA
    const totalAbastece = await fetchDataAndSum(
      '/abastecimentocaixa/abasteceUsuarioPeriodo',
      { UsuarioId: watchUsuario, DataI: dataIni, DataF: dataFin },
      ['Valor'], // Campos a serem somados
      setEntAbasteceMeuCaixa
    );

    saldoMeuCaixa1 += totalAbastece; // Atualizando o saldo com a entrada

    // SANGRIA
    const totalSangria = await fetchDataAndSum(
      '/sangria/lancamentoUsuarioPeriodo',
      { UsuarioId: watchUsuario, DataI: dataIni, DataF: dataFin },
      ['ValorDinheiro', 'ValorCheque'], // Campos a serem somados
      setSaiSangriaMeuCaixa
    );

    saldoMeuCaixa1 -= totalSangria;

    let usuarioSelecionado = usuarios.find((user: any) => user.UsuarioId == watchUsuario)?.Apelido

    // DIARIO FINANCEIRO
    const { data: diarioData } = await api.get('/diariofinanceiro/lancamentoUsuarioPeriodo', {
      params: {
        LancamentoOperador: usuarioSelecionado,
        DataI: dataIni,
        DataF: dataFin,
      },
    });

    if (diarioData) {
      diarioData.forEach((item: any) => {

        let valor = item.Valor + item.ValorMulta + item.ValorJuros
          + item.ValorComplementar01 + item.ValorComplementar02 + item.ValorComplementar03
          + item.ValorComplementar04 + item.ValorComplementar05 + item.ValorComplementar06;

        valor = valor < 0 ? valor * -1 : valor;

        if (['OUTPAG', 'BAIAUT', 'RECPAG', 'RECADI', 'APAGAR'].includes(item.Tarefa) && item.Opcao === 'EST') {
          resumoEstorno += valor;
        }

        if ((item.Tarefa === 'APAGAR' && item.Opcao === 'INC') || (item.Tarefa !== 'APAGAR' && item.Opcao === 'EST')) {

          if (item.Tarefa === 'APAGAR' && item.Opcao === 'INC') {
            resumoContasAPagar += valor;
          }

          if (item.Tipo === '1') {
            if (item.FlgMeuCaixa === true) {
              saidaDinheiroMeuCaixa += valor;
            } else {
              saidaDinheiroOutras += valor;
            }
          }

          if (item.Tipo === '3') {
            if (item.FlgMeuCaixa === true) {
              saidaCartaoMeuCaixa += valor;
            } else {
              saidaCartaoOutras += valor;
            }
          }

          if (item.Tipo === '4') {
            if (item.FlgMeuCaixa === true) {
              saidaOutrosMeuCaixa += valor;
            } else {
              saidaOutrosOutras += valor;
            }
          }
        } else {
          if ((['OUTPAG', 'BAIAUT', 'RECPAG', 'RECADI'].includes(item.Tarefa) && item.Opcao === 'INC') || (item.Tarefa === 'APAGAR' && item.Opcao === 'EST')) {

            if (item.Tipo === '1') {
              if (item.FlgMeuCaixa === true) {
                entradaDinheiroMeuCaixa += valor;
              } else {
                entradaDinheiroOutras += valor;
              }
            }

            if (item.Tipo === '3') {
              if (item.FlgMeuCaixa === true) {
                entradaCartaoMeuCaixa += valor;
              } else {
                entradaCartaoOutras += valor;
              }
            }

            if (item.Tipo === '4') {
              if (item.FlgMeuCaixa === true) {
                entradaOutrosMeuCaixa += valor;
              } else {
                entradaOutrosOutras += valor;
              }
            }

            if (['RECPAG', 'RECADI'].includes(item.Tarefa) && item.Opcao === 'INC') {
              resumoPgtoMensalidade += valor;
            }

            if (['BAIAUT'].includes(item.Tarefa) && item.Opcao === 'INC') {
              resumoPgtoBaixa += valor;
            }

            if (['OUTPAG'].includes(item.Tarefa) && item.Opcao === 'INC') {
              resumoOutrosRec += valor;
            }
          }
        }
      });

      // Atualiza o estado da entrada de dinheiro do meu caixa
      setEntDinheiroMeuCaixa(entradaDinheiroMeuCaixa);

      // Atualiza o estado da saída de dinheiro do meu caixa
      setSaiDinheiroMeuCaixa(saidaDinheiroMeuCaixa);

      // Atualiza o estado da entrada de cartão do meu caixa
      setEntCartaoMeuCaixa(entradaCartaoMeuCaixa);

      // Atualiza o estado da saída de cartão do meu caixa
      setSaiCartaoMeuCaixa(saidaCartaoMeuCaixa);

      // Atualiza o estado da entrada de outros do meu caixa
      setEntOutrosMeuCaixa(entradaOutrosMeuCaixa);

      // Atualiza o estado da saída de outros do meu caixa
      setSaiOutrosMeuCaixa(saidaOutrosMeuCaixa);

      // Atualiza o estado da entrada de dinheiro de Outras Fontes
      setEntDinheiroOutrasFontes(entradaDinheiroOutras);

      // Atualiza o estado da saída de dinheiro de Outras Fontes
      setSaiDinheiroOutrasFontes(saidaDinheiroOutras);

      // Atualiza o estado da saída de cartão de Outras Fontes
      setEntCartaoOutrasFontes(entradaCartaoOutras);

      // Atualiza o estado da saída de cartão de Outras Fontes
      setSaiCartaoOutrasFontes(saidaCartaoOutras);

      // Atualiza o estado da saída de Outros de Outras Fontes
      setEntOutrosOutrasFontes(entradaOutrosOutras);

      // Atualiza o estado da saída de Outros de Outras Fontes
      setSaiOutrosOutrasFontes(saidaOutrosOutras);

      // Atualiza o estado da resumo de Pgto Mensalidade
      setResumoPgMensalidade(resumoPgtoMensalidade);

      // Atualiza o estado da resumo de Pgto Baixa Automática
      setResumoPgBaixa(resumoPgtoBaixa);

      // Atualiza o estado da resumo de Outros Recebimentos
      setResumoOutrosRecebimentos(resumoOutrosRec);

      // Atualiza o estado da resumo de Estornos
      setResumoEstornos(resumoEstorno);

      // Atualiza o estado da resumo de Contas a Pagar
      setResumoAPagar(resumoContasAPagar);

      // Calcula o total de entrada considerando a coluna meu caixa
      const calculoEntradaMeuCaixa = entradaDinheiroMeuCaixa + entradaCartaoMeuCaixa + entradaOutrosMeuCaixa;

      // Calcula o total de entrada considerando a coluna Outras Fontes
      const calculoEntradaOutrasFontes = entradaDinheiroOutras + entradaCartaoOutras + entradaOutrosOutras;

      // Calcula o total de saída considerando a coluna meu caixa
      const calculoSaidaMeuCaixa = totalSangria + saidaDinheiroMeuCaixa + saidaCartaoMeuCaixa + saidaOutrosMeuCaixa;

      // Calcula o total de saída considerando a coluna Outras Fontes
      const calculoSaidaOutrasFontes = saidaDinheiroOutras + saidaCartaoOutras + saidaOutrosOutras;

      // Atualiza o estado com o total calculado
      setTotalEntradaMeuCaixa(calculoEntradaMeuCaixa);
      setTotalEntradaOutrasFontes(calculoEntradaOutrasFontes)

      // Atualiza o estado com o total calculado
      setTotalSaidaMeuCaixa(calculoSaidaMeuCaixa);
      setTotalSaidaOutrasFontes(calculoSaidaOutrasFontes);

      saldoMeuCaixa1 += calculoEntradaMeuCaixa - calculoSaidaMeuCaixa
      saldoOutrasFontes1 += calculoEntradaOutrasFontes - calculoSaidaOutrasFontes

      // Atualizando o saldo final
      setSaldoMeuCaixa(saldoMeuCaixa1); // Exibir o saldo formatado com 2 casas decimais
      setSaldoOutrasFontes(saldoOutrasFontes1); // Exibir o saldo formatado com 2 casas decimais
      setSaldoTotal(saldoMeuCaixa1 + saldoOutrasFontes1); // Exibir o saldo formatado com 2 casas decimais
    }

    setLoading(false)
  };

  const resetarCampos = () => {
    setEntAbasteceMeuCaixa(0);
    setEntDinheiroMeuCaixa(0);
    setEntDinheiroOutrasFontes(0);
    setEntCartaoMeuCaixa(0);
    setEntCartaoOutrasFontes(0);
    setEntOutrosMeuCaixa(0);
    setEntOutrosOutrasFontes(0);
    setTotalEntradaMeuCaixa(0);
    setTotalEntradaOutrasFontes(0);

    setSaiSangriaMeuCaixa(0);
    setSaiDinheiroMeuCaixa(0);
    setSaiCartaoMeuCaixa(0);
    setSaiOutrosMeuCaixa(0);
    setSaiDinheiroOutrasFontes(0);
    setSaiCartaoOutrasFontes(0);
    setSaiOutrosOutrasFontes(0);
    setTotalSaidaMeuCaixa(0);
    setTotalSaidaOutrasFontes(0);

    setResumoPgMensalidade(0);
    setResumoPgBaixa(0);
    setResumoOutrosRecebimentos(0);
    setResumoEstornos(0);
    setResumoAPagar(0);

    setSaldoMeuCaixa(0);
    setSaldoOutrasFontes(0);
    setSaldoTotal(0);

    setValue('DataInicial', new Date())
    setValue('DataFinal', new Date())
    setValue('UsuarioId', null)
  };

  const onSubmit = async () => {
    let nomeUsuario;

    if (!watchAcao) {
      return showToastError('Selecione a ação!');
    }

    if (!watchDataInicial || !watchDataFinal) {
      return showToastError('Selecione um período!');
    }


    if (!watchUsuario) {
      return showToastError('Selecione o Usuário');
    }

    let consultaUsuario = await api.get('/usuario/buscaNomeUsuario', { params: { EmpresaId: periodoSelecionado?.EmpresaId, UsuarioId: watchUsuario } })

    if (consultaUsuario.data) {
      nomeUsuario = consultaUsuario.data[0].Apelido
    }

    let dadosGravacao = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      DataI: format(new Date(watchDataInicial), 'yyyy-MM-dd'),
      DataF: format(new Date(watchDataFinal), 'yyyy-MM-dd'),
      Operador: nomeUsuario,
      EncerramentoData: format(new Date(), 'yyyy-MM-dd'),
      EncerramentoHora: format(new Date(), 'HH:mm'),
      EncerramentoOperador: usuario?.Apelido,
      Acao: watchAcao
    }

    try {
      const result = await api.post('/atualizacaixa', dadosGravacao)

      let mensagem = watchAcao === 'A' ? 'Caixa aberto com sucesso!' : 'Caixa encerrado com sucesso!'

      if (result) {

        resetarCampos()

        toast.showToast({
          severity: 'success',
          summary: 'Atenção',
          detail: mensagem,
        });
      }
    } catch (error) {
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar!',
      });
    }
  }

  // Função auxiliar para exibir toast de erro
  const showToastError = (message: string) => {
    toast.showToast({
      severity: 'error',
      summary: 'Atenção',
      detail: message,
    });
    return false;
  };;

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setValue('DataInicial', new Date())
    setValue('DataFinal', new Date())
  }, []);

  useEffect(() => {
    if (watchAcao === 'C') {
      setHabilitaExecutar(true)
    } else {
      setHabilitaExecutar(false)
    }

    if (watchAcao === 'F') {
      toast.showToast({
        severity: 'info',
        summary: 'Atenção',
        detail: "Após encerrar o caixa, NENHUMA movimentação financeira poderá ser registrada nesta data.",
        life: 20000
      });
    }
    resetarCampos()
  }, [watchAcao])


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772b3' }}>
              <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Encerramento de Caixa</h5>
            </div>

            <div className="grid">
              {/* Fieldset Ações */}
              <div className="col-12 lg:col-3 md:col-3">
                <SCFieldset legend="Ações" className="pt-0 mt-2 w-full" style={{ color: 'blue', fontWeight: 'bold' }}>
                  <div className="col-12 align-items-center">
                    <SCDropdown
                      name="Acao"
                      errors={errors}
                      options={ACAO_CAIXA}
                      optionLabel="descricao"
                      optionValue="value"
                      placeholder="Selecione"
                      value={watchAcao}
                      style={{ width: '100%' }}
                      onChange={(e) => setValue('Acao', e.target.value)}
                      filter={false}
                      label='Selecione a ação desejada'
                    />
                  </div>
                </SCFieldset>
              </div>

              {/* Fieldset Movimentação de Caixa */}
              <div className="col-12 md:col-9 flex">
                <SCFieldset legend="Movimentação de Caixa" className="pt-0 mt-2 w-full">
                  <div className="grid align-items-center">
                    {/* Data Inicial */}
                    <div className="col-12 md:col-3">
                      <SCCalendar
                        control={control}
                        label="Data Inicial"
                        name="DataInicial"
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        placeholder="Data Inicial"
                      />
                    </div>

                    {/* Data Final */}
                    <div className="col-12 md:col-3">
                      <SCCalendar
                        control={control}
                        label="Data Final"
                        name="DataFinal"
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        placeholder="Data Final"
                      />
                    </div>

                    {/* Usuário */}
                    <div className="col-12 md:col-4">
                      <SCDropdown
                        name="UsuarioId"
                        errors={errors}
                        options={usuarios}
                        optionLabel="Apelido"
                        optionValue="UsuarioId"
                        placeholder="Selecione o Usuário"
                        label="Usuário"
                        value={watchUsuario}
                        style={{ width: '100%' }}
                        onChange={(e) => setValue('UsuarioId', e.target.value)}
                      />
                    </div>

                    {/* Botão Pesquisar */}
                    <div className="col-12 md:col-2 mt-3">
                      <Button
                        label="Pesquisar"
                        icon="pi pi-search"
                        className="p-button-info w-full"
                        type="button"
                        onClick={loadPesquisa}
                      />
                    </div>
                  </div>
                </SCFieldset>
              </div>
            </div>

            {loading ? (
              <Loading />
            ) : (
              <>
                <div className="grid">
                  <div className='col-12 lg:col-4'>
                    <SCFieldset legend='Entradas' style={{ color: 'green' }}>
                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}></div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Meu Caixa</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Outras Fontes</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Abastece MeuCaixa:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {entAbasteceMeuCaixa}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}></span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Dinheiro:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {entDinheiroMeuCaixa.toFixed(2)}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {entDinheiroOutrasFontes.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Cartão:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {entCartaoMeuCaixa.toFixed(2)}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {entCartaoOutrasFontes.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Outros:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {entOutrosMeuCaixa.toFixed(2)}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {entOutrosOutrasFontes.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Total:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {totalEntradaMeuCaixa.toFixed(2)}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {totalEntradaOutrasFontes.toFixed(2)}</span>
                        </div>
                      </div>
                    </SCFieldset>
                  </div>

                  <div className='col-12 lg:col-4'>
                    <SCFieldset legend='Saída' style={{ color: 'red' }}>
                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}></div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Meu Caixa</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>Outras Fontes</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Sangria:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {saiSangriaMeuCaixa}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}></span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Dinheiro:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {saiDinheiroMeuCaixa.toFixed(2)}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {saiDinheiroOutrasFontes.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Cartão:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {saiCartaoMeuCaixa.toFixed(2)}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {saiCartaoOutrasFontes.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Outros:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {saiOutrosMeuCaixa.toFixed(2)}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {saiOutrosOutrasFontes.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-5 text-sm" style={{ textAlign: "right" }}>Total:</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {totalSaidaMeuCaixa.toFixed(2)}</span>
                        </div>

                        <div className="col-3" style={{ textAlign: "right" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {totalSaidaOutrasFontes.toFixed(2)}</span>
                        </div>
                      </div>
                    </SCFieldset>
                  </div>

                  <div className='col-12 lg:col-4'>
                    <SCFieldset legend='Resumo' className='pt-0 pb-0'>
                      <div className="grid">
                        <div className="col-8 text-sm" style={{ textAlign: "right", color: 'green' }}>Abastece MeuCaixa (+):</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {entAbasteceMeuCaixa}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-8 text-sm" style={{ textAlign: "right", color: 'green' }}>Recebimento de Mensalidades (+):</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {resumoPgMensalidade.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-8 text-sm" style={{ textAlign: "right", color: 'green' }}>Recebimento de Mensalidades por Baixa Automática (+):</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {resumoPgBaixa.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-8 text-sm" style={{ textAlign: "right", color: 'green' }}>Outros Recebimentos (+):</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {resumoOutrosRecebimentos.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-8 text-sm" style={{ textAlign: "right", color: 'red' }}>Estornos (-):</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {resumoEstornos.toFixed(2)}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-8 text-sm" style={{ textAlign: "right", color: 'red' }}>Sangria (-):</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {saiSangriaMeuCaixa}</span>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-8 text-sm" style={{ textAlign: "right", color: 'red' }}>A Pagar (-):</div>
                        <div className="col-3" style={{ textAlign: "left" }}>
                          <span className='text-sm' style={{ color: "black", fontWeight: "bold" }}>R$ {resumoAPagar.toFixed(2)}</span>
                        </div>
                      </div>
                    </SCFieldset>
                  </div>
                </div>

                <div className='grid'>
                  <div className='col-12 pt-0'>
                    <SCFieldset legend='Saldo'>
                      <div className='grid flex justify-content-between align-items-center p-0'>
                        <div className="grid col-12">
                          <div className="col-2" style={{ textAlign: 'right' }}>Meu Caixa:</div>
                          <div className="col-2" >
                            <span style={{ color: "black", fontWeight: "bold" }}>R$ {saldoMeuCaixa.toFixed(2)}</span>
                          </div>

                          <div className="col-2" style={{ textAlign: 'right' }}>Outras Fontes:</div>
                          <div className="col-2">
                            <span style={{ color: "black", fontWeight: "bold" }}>R$ {saldoOutrasFontes.toFixed(2)}</span>
                          </div>

                          <div className="col-2" style={{ textAlign: 'right' }}>Total Geral:</div>
                          <div className="col-2">
                            <span style={{ color: "black", fontWeight: "bold" }}>R$ {saldoTotal.toFixed(2)}</span>
                          </div>
                        </div>
                      </div>
                    </SCFieldset>
                  </div>
                </div>
              </>
            )}

            <div className="grid">
              <div className="col-12 flex justify-content-end align-items-end">
                <div className="col-12 md:col-2 mt-4">
                  <Button
                    label="Executar"
                    icon="pi pi-plus-o"
                    className="p-button-success"
                    type="button"
                    style={{ width: '100%' }}
                    onClick={onSubmit}
                    disabled={habilitaExecutar}
                  />
                </div>
              </div>
            </div>

          </div>
        </div >
      </div >
    </form >
  );
};

export default EncerramentoCaixa;