import { useState, useEffect, useRef, SetStateAction } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDropdown, SCFieldset } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { CheckboxChangeParams } from 'primereact/checkbox';
import Loading from '../components/Loading';
import { confirmDialog } from 'primereact/confirmdialog';


const FaturaCartao = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'FaturaCartao';

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [aPagar, setAPagar] = useState<any>([]);
  const [cartao, setCartao] = useState<any>([]);
  const [valorTotalSelecionado, setValorTotalSelecionado] = useState(0);
  const [loading, setLoading] = useState(false)
  const [disableQuitar, setDisableQuitar] = useState<boolean>(true)
  const [fonteVisivel, setFonteVisivel] = useState<boolean>(true)
  const [fontes, setFontes] = useState<any>(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1720);

  const methods = useForm();
  const { control, watch, getValues, setValue } = methods;

  const watchCartaoInstitucional = watch('CartaoInstitucionalId')
  const watchDataI = watch('DataInicial')
  const watchDataF = watch('DataFinal')
  const watchFonte = watch('FonteId')

  const load = async () => {
    setLoading(true)
    setFonteVisivel(true)
    const result = await api.get('/cartoes/listCartaoInstitucional', {
      params:
      {
        Institucional: 1,
        Tipo: 'D'
      }
    });

    if (result.data) {
      setCartao(result.data);
    } else {
      setCartao([])
    }

    const resultFontes = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });

    if (resultFontes.data) {
      setFontes(resultFontes.data);
    } else {
      setFontes([]);
    }

    const formattedDataI = watchDataI ? new Date(watchDataI).toISOString().split('T')[0] : null;
    const formattedDataF = watchDataF ? new Date(watchDataF).toISOString().split('T')[0] : null;

    const result2 = await api.get('/apagar/listLiquidaFatura', {
      params: {
        DataI: formattedDataI,
        DataF: formattedDataF,
        CartaoInstitucionalId: watchCartaoInstitucional,
        FaturasQuitadas: false
      }
    })
    if (result2.data) {
      setAPagar(result2.data)
    } else {
      setAPagar([])
    }

    setLoading(false)
  };

  useEffect(() => {
    load();
  }, []);

  const handleCheckboxChange = (rowData: any, checked: boolean) => {
    const updatedAPagar = aPagar.map((item: any) => {
      if (item.ApagarId === rowData.ApagarId) {
        return { ...item, Selecionado: checked ? 1 : 0 };
      }
      return item;
    });

    setAPagar(updatedAPagar);
    const algumSelecionado = updatedAPagar.some((item: any) => item.Selecionado === 1);
    setDisableQuitar(!algumSelecionado);
    setFonteVisivel(!algumSelecionado)
    atualizarValorTotalSelecionado(updatedAPagar);
  };

  const confirm = () => {
    confirmDialog({
      message: (<div style={{ fontSize: '16px' }} className='col-12'>
        <div>
          Confirme se selecionou todos os lançamentos da fatura do cartão.
        </div>
        <div className='pt-2'>
          DESEJA PROSSEGUIR COM A QUITAÇÃO?
        </div>
      </div>),
      header: 'Atenção!',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept,
      style: { maxWidth: '600px', width: '80%' }
    });
  };

  const accept = async () => {
    onSubmit();
  }

  const onSubmit = async () => {
    try {
      let valorTotal = 0;
      const hours = String(new Date().getHours()).padStart(2, '0');
      const minutes = String(new Date().getMinutes()).padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;

      aPagar.forEach((item: any) => {
        if (item.Selecionado === 1) {
          valorTotal += item.Valor;
        }
      });

      const dados = {
        Valor: valorTotal,
        LancamentoData: new Date(),
        LancamentoHora: formattedTime,
        LancamentoOperador: usuario?.Apelido,
      }

      const novaFatura = await api.post('/liquidaFatura', dados);
      const idFatura = novaFatura.data[0];

      await Promise.all(
        aPagar.map(async (item: any) => {
          if (item.Selecionado === 1) {
            const updateApagar = {
              ApagarId: item.ApagarId,
              LiquidaFaturaId: idFatura,
              FonteId: watchFonte,
            }

            await api.put('/apagar/liquidaFatura', updateApagar);

            if (watchFonte) {
              const updateDiario = {
                NumeroRecibo: item.ApagarId,
                FonteId: watchFonte,
              }
              await api.put('/diariofinanceiro/updateFonte', updateDiario);
            }
          }
        })
      );

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      load()

    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const atualizarValorTotalSelecionado = (dados: any[]) => {
    const soma = dados
      .filter(item => item.Selecionado === 1) // Filtrar apenas os itens selecionados
      .reduce((acc, item) => acc + (item.Valor || 0), 0); // Somar os valores
    setValorTotalSelecionado(soma);
  };

  useEffect(() => {
    atualizarValorTotalSelecionado(aPagar);
  }, [aPagar]);

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth >= 1720);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Fatura de Cartão - Abertas</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>
          <SCFieldset legend='Filtros' className='col-12 mt-2 pt-0'>
            <div className='grid'>
              <div className="col-12 md:col-2">
                <SCCalendar
                  control={control}
                  label='Data Pagamento Inicial'
                  name='DataInicial'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Inicial'
                />
              </div>

              <div className="col-12 md:col-2">
                <SCCalendar
                  control={control}
                  label='Data Pagamento Final'
                  name='DataFinal'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Final'
                />
              </div>
              <div className="col-12 md:col-6">
                <SCDropdown
                  name="CartaoInstitucionalId"
                  label='Cartão Institucional'
                  options={cartao}
                  optionLabel='NomeCartao'
                  optionValue='CartoesId'
                  value={watchCartaoInstitucional}
                  onChange={(e) => setValue('CartaoInstitucionalId', e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className='col-12 md:col-2 flex align-items-end'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined" type='button' onClick={load} />
              </div>
            </div>
          </SCFieldset>

          {loading ? <Loading /> : (
            <DataTable
              size='small'
              stripedRows
              value={aPagar}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="NumeroDocumento" header="Nº Documento" sortable headerStyle={{ width: '10%' }} ></Column>
              <Column field="DataPagamento" header="Data Pagamento" sortable body={(rowData: any) => {
                if (rowData.DataPagamento) {
                  return (
                    <span>{rowData.DataPagamentoString}</span>
                  );
                }
                return null;
              }} style={{ width: '16%' }} />
              <Column field="NomeCartao" header="Cartão Institucional" sortable headerStyle={{ width: '20%' }} ></Column>
              <Column field="Nome" header="Credor" sortable headerStyle={{ width: '20%' }} ></Column>
              <Column field="Referente" header="Referente" sortable headerStyle={{ width: '20%' }} ></Column>

              <Column field="Valor" header="Valor" align='center' sortable body={(rowData: any) => {
                return (
                  <span>R$ {rowData.Valor?.toFixed(2).replace('.', ',')}</span>
                );
              }} style={{ width: '15%' }}
              />

              <Column
                body={(rowData: any) => (
                  <div className="col-12 flex justify-content-center">
                    <SCCheckbox
                      checked={rowData.Selecionado === 1}
                      onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                      id={rowData.ApagarId}
                      name="Selecionado"
                    />
                  </div>
                )}
                alignHeader='center'
                align='center'
              />
            </DataTable>
          )}

          {fonteVisivel ? <div></div> : <SCFieldset legend='Dados de Quitação' className='col-12 mt-2 pt-0'>
            <div className='grid'>
              <div className='col-12 md:col-6 mt-3' style={{ textAlign: 'center', paddingRight: '10px', fontSize: '18px' }}>
                <strong>Total Selecionado: </strong> R$ {valorTotalSelecionado.toFixed(2).replace('.', ',')}
              </div>
              <div className={`col-12 ${isLargeScreen ? 'md:col-3 ml-auto' : 'md:col-6'}`}>
                <SCDropdown
                  name="FonteId"
                  label='Fonte'
                  options={fontes}
                  optionLabel='Codigo'
                  optionValue='FonteId'
                  value={watchFonte}
                  onChange={(e) => setValue('FonteId', e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </SCFieldset>}

          <div className='grid flex justify-content-center'>
            <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
            <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
              <Button label='Quitar Fatura' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={confirm} disabled={disableQuitar} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default FaturaCartao;