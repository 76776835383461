import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputSwitch, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import PlanoPagamentoForm from './PlanoPagamentoForm';
import IPlanoPagamento from '../interfaces/IPlanoPagamento';
import OfertaDisciplinaForm from './OfertaDisciplinaForm';
import OfertaDisciplinaMateriaForm from './OfertaDisciplinaMateriaForm';
import { set } from 'date-fns';
import OfertaDisciplinaMateriaProfessorForm from './OfertaDisciplinaMateriaProfessorForm';
import IOfertaDisciplina from '../interfaces/IOfertaDisciplina';
import { InputSwitchChangeParams } from 'primereact/inputswitch';


const AtivaDesativaOrdenaPeriodo = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado } = useAuth();
  const tarefa = 'OfertaDisciplina';

  const [lista, setLista] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const load = async () => {
    setLoading(true);
    const result = await api.get('/usuario/listaPeriodosEmpresa', {params: { EmpresaId: usuario?.EmpresaId, Ativo: 'N' }});

    if (result.data)
      setLista(result.data);
    else
      setLista([]);

    setLoading(false);
  }

  const clearState = () => {
    dt?.current?.reset();      
  }

  useEffect(() => {
    load().finally(() => clearState());
  }, []);

 
  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const handleUpdatePeriodo = async (id: number, ativo: boolean) => {
    setLoading(true);
    const result = await api.put('/periodo', { PeriodoId: id, Ativo: ativo });
    console.log('result', result.data);
    if (result.data) {
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      load();
    } else {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: ALERTAS.erroGravar });
    }
    setLoading(false);
  }

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Ativa / Desativa / Ordena Período
            </h5>
          </div>

        
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={lista}
              dataKey="id"
              stateKey='tabPeriodos'
              stateStorage='local'
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll"
            >
              <Column header="Ordem" field='Ordem' bodyStyle={{ width: 50 }} sortable />
              <Column header="Descricao" field='Descricao' sortable />
              <Column header='Ativo' body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-center">
                    <SCInputSwitch 
                    name="switchPeriodo" 
                    className="p-button-rounded p-button-info" 
                    onChange={(e: InputSwitchChangeParams) => {
                      handleUpdatePeriodo(rowData.Id, e.value);
                    }} 
                    checked={rowData.Ativo}
                    />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}

        </div>
      </div>
    </div>
  );
};

export default AtivaDesativaOrdenaPeriodo;
